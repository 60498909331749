$blue: #23bcd8;
$blue-light: #e9f8fb;

$purple: #6e19cf;
$purple-light: #f7f4fc;

$gray: #8a8a8a;
$gray-light: #f1f1f1;

// blue
.border-blue-1x {
  border: 1px solid $blue !important;
}
.border-blue-2x {
  border: 2px solid $blue !important;
}
.color-blue {
  color: $blue !important;
}
.background-color-blue {
  background-color: $blue !important;
}
.background-color-blue-light {
  background-color: $blue-light;
}
.fill-blue {
  fill: $blue !important;
}
.box-shadow-blue {
  // -moz-box-shadow:  0px 8px 6px #29b4d529, inset 0px 3px 6px #00000029, !important;  
  // -webkit-box-shadow:  0px 8px 6px #29b4d529, inset 0px 3px 6px #00000029, !important;
  box-shadow: inset 0px 2px 6px #29b4d529, 0px 4px 6px #00000029 !important;
 
}
// ::ng-deep .tooltip-blue{
//   --mdc-plain-tooltip-container-color: $blue !important ;
// }

// purple
.border-purple-1x {
  border: 1px solid $purple !important;
}
.border-purple-2x {
  border: 1px solid $purple !important;
}
.color-purple {
  color: $purple !important;
}
.background-color-purple {
  background-color: $purple !important;
}
.background-color-purple-light {
  background-color: $purple-light;
}
.fill-purple {
  fill: $purple !important;
}
.box-shadow-purple {
  box-shadow: inset 0px 2px 6px #00000029, 0px 4px 6px #6e19cf29 !important;
 
  
}
// ::ng-deep .tooltip-purple{
//   --mdc-plain-tooltip-container-color: #6e19cf !important ;
// }

// gray
.border-gray-1x {
  border: 1px solid $gray !important;
}
.border-gray-2x {
  border: 1px solid $gray !important;
}
.color-gray {
  color: $gray !important;
}
.background-color-gray {
  background-color: $gray !important;
}
.background-color-gray-light {
  background-color: $gray-light;
}
.fill-gray {
  fill: $gray !important;
}
.box-shadow-gray {
  box-shadow: inset 0px 2px 6px #00000029, 0px 4px 6px #0000001a !important;
}
// ::ng-deep .tooltip-gray{
//   --mdc-plain-tooltip-container-color: $gray !important ;
// }
