// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;800&display=swap");
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$chill-palette: (
  800: #5a2293,
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Chill-primary: mat.define-palette(mat.$deep-purple-palette, 800);
$Chill-accent: mat.define-palette(mat.$deep-purple-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Chill-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Chill-theme: mat.define-light-theme(
  (
    color: (
      primary: $Chill-primary,
      accent: $Chill-accent,
      warn: $Chill-warn,
    ),
  )
);
// Define a dark theme
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette(mat.$pink-palette),
      accent: mat.define-palette(mat.$blue-grey-palette),
    ),
    // Only include `typography` and `density` in the default dark theme.
    typography: mat.define-typography-config(),
    density: 0,
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Chill-theme);

// @include mat.core-theme($dark-theme);
// @include mat.button-theme($Chill-theme);

/* You can add global styles to this file, and also import other style files */
@import "shared-styles/policy.scss";
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: Rubik!important;
  font-size: 10px;
}
body {
  font-family: Rubik!important;

  app-root {
    display: flow-root;
    height: 100%;
  }
}
